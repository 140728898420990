import { createContext } from "react";
import { responsizes } from "../utils/constants";

import { getQueryParam, SEARCH_QUERY } from "../utils/routes";
import { getFromSession, SELECTED_CARD_KEY } from "../utils/sessionStorage";

export const initialState = {
  db: [],
  selectedCard: getQueryParam(SEARCH_QUERY, "")
    ? getFromSession(SELECTED_CARD_KEY, "")
    : "",
  searchTerm: getQueryParam(SEARCH_QUERY, ""),
  prices: {},
  showAllFilters: window.innerWidth >= responsizes["2xl"],
  filtersDefinition: {},
};

export const AppContext = createContext(initialState);
