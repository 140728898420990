export function parsePrices(card, prices) {
  if (!card || !prices) {
    return [];
  }
  const cardPrices = (card.tcg_details || [])
    .map((d) => {
      return {
        ...d,
        prices: prices[d.product_id] || [],
      };
    })
    .filter(Boolean);
  if (!cardPrices) {
    return cardPrices;
  }
  return cardPrices
    .reduce((acc, product) => {
      const productPrices = product.prices.map((cp) => ({
        ...cp,
        series: product.group_name,
        url: product.url,
        product_id: product.product_id,
        rarity_match: product.rarity_match,
      }));
      return [...acc, ...productPrices];
    }, [])
    .sort((a, b) => {
      if (a.lowPrice < b.lowPrice) {
        return -1;
      } else return 1;
    });
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function getLowestRarityPrice(cardPrices) {
  let lowest = {};
  if (!cardPrices) {
    return lowest;
  }
  if (cardPrices.length === 0) {
    return lowest;
  }

  lowest = cardPrices.find((c) => c.rarity_match) || cardPrices[0];

  return lowest;
}
