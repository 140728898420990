import React, { useEffect, useState, useContext } from "react";

import { CardGrid } from "./CardGrid";
import { Text } from "./Text";

import { buildDeck, SECTIONS } from "../utils/encode";
import { LEADER } from "../utils/constants";
import { saveToSession, SELECTED_CARD_KEY } from "../utils/sessionStorage";
import { DECK_QUERY, getQueryParam } from "../utils/routes";
import { AppContext } from "../context/AppContext";
import { setMetadata } from "../utils/metatags";
import { doSearch } from "../utils/searchParser";
import { getLowestRarityPrice, parsePrices, formatter } from "../utils/prices";
import { focusOnCard, stopDefaults } from "../utils/dom";

export const DeckViewer = () => {
  const [deck, updateDeck] = useState({});
  const [activeGrid, updateActiveGrid] = useState("search");
  const state = useContext(AppContext);
  useEffect(() => {
    let encodedStr = getQueryParam(DECK_QUERY, "");
    updateDeck(buildDeck(encodedStr, state.db));
    saveToSession(SELECTED_CARD_KEY, "");
    setMetadata([
      { property: "og:title", content: "DBSCGT Deck Viewer" },
      { property: "og:image", content: "" },
      { property: "og:description", content: "" },
    ]);
  }, [state.db]);

  const focusOnGrid = (name, event) => {
    stopDefaults(() => {
      updateActiveGrid(name);
      setTimeout(() => focusOnCard(`card_${name}_0`), 200);
    })(event);
  };

  useEffect(() => {
    const handleKey = (event) => {
      if (window.document.activeElement.tagName === "INPUT") {
        return;
      }
      switch (event.key) {
        case "m": {
          focusOnGrid("MAIN", event);
          break;
        }
        case "k": {
          focusOnGrid("SIDE", event);
          break;
        }
        case "l": {
          focusOnGrid("LEADER", event);
          break;
        }
        default: {
          break;
        }
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => document.removeEventListener("keydown", handleKey);
  }, [updateActiveGrid]);

  return (
    <div
      className={`min-h-screen h-full min-w-screen max-w-screen m-0 p-0 flex flex-row`}
    >
      <div
        className={`w-52 hidden md:${state.searchTerm ? "block" : "hidden"}`}
      >
        <div className="h-screen overflow-scroll">
          <CardGrid
            list={doSearch(
              state.searchTerm,
              state.filtersDefinition,
              state.db
            ).slice(0, 20)}
            gridID={"search"}
            baseDeck={deck}
            cardSize="s"
            allowDeckBuilding={true}
            baseDeckUpdate={updateDeck}
            activeGrid={activeGrid}
            updateActiveGrid={updateActiveGrid}
          />
        </div>
      </div>
      <div className=" flex-1">
        <DeckSection
          name={LEADER}
          section="main"
          deck={deck}
          includeType={LEADER}
          updateDeck={updateDeck}
          prices={state.prices}
          selectedCard={state.selectedCard}
          activeGrid={activeGrid}
          defaultCollapsed={true}
          updateActiveGrid={updateActiveGrid}
        />
        {SECTIONS.map((section) => (
          <DeckSection
            key={section}
            name={section.toUpperCase()}
            section={section}
            deck={deck}
            excludeType={LEADER}
            updateDeck={updateDeck}
            prices={state.prices}
            selectedCard={state.selectedCard}
            activeGrid={activeGrid}
            defaultCollapsed={false}
            updateActiveGrid={updateActiveGrid}
          />
        ))}
      </div>
    </div>
  );
};

const DeckSection = ({
  name,
  deck,
  gridID = name,
  section,
  updateDeck,
  includeType,
  excludeType,
  selectedCard,
  prices,
  activeGrid,
  defaultCollapsed,
  updateActiveGrid,
}) => {
  const [collapsed, updateCollapsed] = useState(defaultCollapsed);

  if (!deck) {
    return null;
  }
  let list = Object.values(deck).filter((card) => card.deck[section] > 0);

  if (includeType) {
    list = list.filter((card) => card.type === includeType);
  }

  if (excludeType) {
    list = list.filter((card) => card.type !== excludeType);
  }

  const total = list.reduce((tot, card) => tot + card.deck[section], 0);
  if (total === 0) {
    return null;
  }

  let header;
  if (name === "LEADER") {
    header =
      list.length > 0
        ? `[${list[0].number}] - ${list[0].title} `.toUpperCase()
        : "NO LEADER SELECTED";
  } else {
    header = `${name}: ${total || 0} `;
  }

  const totalPrice = list.reduce((sum, card) => {
    return (
      sum + (getLowestRarityPrice(parsePrices(card, prices)) || {}).lowPrice ||
      0
    );
  }, 0);
  return (
    <div className="pb-2">
      <div
        className={`flex cursor-pointer justify-center items-center p-4 bg-green-700 flex-1 text-xl  ${
          selectedCard ? "2xl:mr-detail-xl" : ""
        }`}
        onClick={() => updateCollapsed(!collapsed)}
      >
        <Text>{`${header}(${formatter.format(totalPrice)})`}</Text>
      </div>
      <div
        className={`pl-2 ${
          collapsed ? "hidden" : ""
        } transition-all ease-in-out duration-300`}
      >
        <CardGrid
          list={list}
          gridID={gridID}
          baseDeck={deck}
          cardSize="s"
          allowDeckBuilding={true}
          baseDeckUpdate={updateDeck}
          activeGrid={activeGrid}
          updateActiveGrid={updateActiveGrid}
        />
      </div>
    </div>
  );
};
