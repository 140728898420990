export function setMetadata(tags) {
  const metas = document.getElementsByTagName("meta");
  const head = document.getElementsByTagName("head")[0];
  tags.forEach(({ property, content }) => {
    let metadata;
    for (let index = 0; index < metas.length; index++) {
      if (metas[index].getAttribute("property") === property) {
        metadata = metas[index];
      }
    }
    if (metadata) {
      metadata.setAttribute("content", content);
    } else {
      metadata = document.createElement("meta");
      metadata.setAttribute("property", property);
      metadata.setAttribute("content", content);
      head.appendChild(metadata);
    }
  });
}
