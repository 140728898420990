import { Paragraph, Title } from "./Text";

export const Welcome = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center pt-8 pl-4 pr-4">
      <div className="max-w-3xl">
        <div>
          <Title>Features</Title>
        </div>

        <br />
        <div className="grid lg:grid-cols-2 gap-y-14 lg:gap-y-20 gap-x-28 pb-10">
          <Feature
            icon={
              <g>
                <path d="M15.5 14h-.79l-.28-.27c.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z"></path>
              </g>
            }
          >
            <div>
              <Paragraph>
                Advance features to search by name, skills, color, type, rarity,
                energy, and many more!
              </Paragraph>
              <Paragraph>
                Use keywords and expressions to find a{" "}
                <SearchExample search="ch~21; c=u/g">
                  a card that includes 21 in it's character and is blue/green
                </SearchExample>{" "}
                or be more specific to find{" "}
                <SearchExample search="c=ru; t=b; r=scr; e>5; Goku">
                  a red or blue, secret rare card with energy greater than 5 and
                  goku on its name or skills{" "}
                </SearchExample>
              </Paragraph>
            </div>
          </Feature>
          <Feature
            className="hidden lg:block"
            icon={
              <g>
                <path d="M20 5h-16c-1.1 0-1.99.9-1.99 2l-.01 10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-9 3h2v2h-2v-2zm0 3h2v2h-2v-2zm-3-3h2v2h-2v-2zm0 3h2v2h-2v-2zm-1 2h-2v-2h2v2zm0-3h-2v-2h2v2zm9 7h-8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2v-2h2v2zm3 3h-2v-2h2v2zm0-3h-2v-2h2v2z"></path>
              </g>
            }
          >
            <div>
              <Paragraph>
                Use handy shortcuts to quickly navigate to through the app
              </Paragraph>
              <Paragraph>
                <Key>←</Key>
                <Key>↑</Key>
                <Key>↓</Key>
                <Key>→</Key>
                Use arrows to navigate through the cards
              </Paragraph>
              <Paragraph>
                <Key>A</Key>
                <Key>S</Key>
                <Key>Q</Key>
                <Key>W</Key> Add and remove cards to the main and side deck
              </Paragraph>
              <Paragraph>
                <Key>F</Key>
                <Key>T</Key>Jump to the search bar and Flip a card
              </Paragraph>
            </div>
          </Feature>

          <Feature
            icon={
              <g>
                <path d="M18 16.08c-.76 0-1.44.3-1.96.77l-7.13-4.15c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7l-7.05 4.11c-.54-.5-1.25-.81-2.04-.81-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
              </g>
            }
          >
            <div>
              <Paragraph>
                Share your search, decks or any card. Everything is easy to
                share, just copy the url and share it with anyone!
              </Paragraph>
            </div>
          </Feature>
          <Feature
            icon={
              <g>
                <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4v-1.9h-4c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9h-4c-1.71 0-3.1-1.39-3.1-3.1zm4.1 1h8v-2h-8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path>
              </g>
            }
          >
            <div>
              <Paragraph>
                Everthing is connected! Easily enhance your search by clicking
                on a keyword skill or trait in the card details!
              </Paragraph>
            </div>
          </Feature>
          <Feature
            icon={
              <g>
                <path d="M7 2v11h3v9l7-12h-4l4-8z"></path>
              </g>
            }
          >
            <div>
              <Paragraph>
                Fast! DBSCG Toolbox is designed to be incredibly fast. We avoid
                any bottleneck because we dont save any of your data. So you can
                focus and finding cards and create your decks fast!
              </Paragraph>
            </div>
          </Feature>
        </div>
      </div>
    </div>
  );
};

const Feature = ({ className, icon, children }) => {
  return (
    <div className={`${className} w-full lg:w-96`}>
      <div className="mb-2 text-white fill-current">
        <svg viewBox="0 0 24 24" className="w-16 h-16">
          {icon}
        </svg>
      </div>
      <Paragraph>{children}</Paragraph>
    </div>
  );
};

export const SearchExample = ({ className, children, search }) => {
  return (
    <a
      href={`/?search=${encodeURIComponent(search)}`}
      className={`${className} text-yellow-300 underline`}
    >
      {children}
    </a>
  );
};

const Key = ({ children }) => {
  return (
    <span className="bg-white text-gray-400 text-lg py-0.5 px-1.5 border border-gray-300 rounded-md w-4 h-3 mr-2">
      {children}
    </span>
  );
};
