export const LEADER = "LEADER";

export const DEFAULT_ITEMS_COUNT = 20;

export const responsizes = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1560,
};

export const cardWidth = 260;

export const costs = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "red",
  "green",
  "black",
  "yellow",
  "blue",
  "X",
];

export const maxAllowedCards = 4;

export const tcgAffiliateCode = "dbsjepz";
