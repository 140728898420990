import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { AppContext } from "../context/AppContext";

import { CostColor } from "./CostColor";

export const Filter = ({ cards, property, onChange = () => {}, value }) => {
  const [options, updateOptions] = useState([]);
  const state = useContext(AppContext);

  useEffect(() => {
    updateOptions(
      state.filtersDefinition[property]
        ? state.filtersDefinition[property].filterValues
        : []
    );
  }, [property, state.filtersDefinition]);

  if (property === "color") {
    return (
      <ColorFilter
        options={options}
        onChange={onChange}
        property={property}
        value={value}
      />
    );
  }
  if (property === "type") {
    return (
      <MultiFilter
        options={options}
        onChange={onChange}
        property={property}
        value={value}
      />
    );
  }
  return (
    <Select
      isMulti={true}
      options={options}
      placeholder={property}
      value={value || []}
      onChange={(value) => onChange(property, value)}
    />
  );
};

const MultiFilter = ({ property, options, onChange, value }) => {
  const [optionsMap, updateOptionsMap] = useState({});
  useEffect(() => {
    const parsedVal = value || [];
    updateOptionsMap(
      options.reduce((acc, option) => {
        acc[option.value] = parsedVal.find((it) => it.value === option.value);
        return acc;
      }, {})
    );
  }, [options, value]);

  return (
    <div className="flex gap-1 flex-wrap justify-center">
      {options.map((option) => {
        const val = option.value;
        return (
          <button
            key={val}
            cost={val}
            className={`${optionsMap[val] ? "bg-red-400" : "bg-white"}
            ${optionsMap[val] ? "text-white" : "text-indigo-700"}
             font-bold p-2 cursor-pointer rounded focus:outline-none`}
            size="m"
            onClick={() => {
              optionsMap[val] = !optionsMap[val];
              onChange(
                property,
                Object.entries(optionsMap)
                  .filter(([_, enabled]) => enabled)
                  .map(([val]) => ({ value: val }))
              );
              updateOptionsMap(optionsMap);
            }}
          >
            {val.toUpperCase()}
          </button>
        );
      })}
    </div>
  );
};

const ColorFilter = ({ property, options, onChange, value }) => {
  const [colors, updateColors] = useState({});
  useEffect(() => {
    const parsedVal = value || [];
    updateColors(
      options.reduce((acc, option) => {
        acc[option.value] = parsedVal.find((it) => it.value === option.value);
        return acc;
      }, {})
    );
  }, [options, value]);

  return (
    <div className="flex gap-1 flex-wrap mb-2">
      {options.map((option) => {
        const val = option.value;
        return (
          <CostColor
            key={val}
            cost={val}
            className={`${
              colors[val] ? "ring-4 ring-red-400" : ""
            } cursor-pointer`}
            size="m"
            onClick={() => {
              colors[val] = !colors[val];

              onChange(
                property,
                Object.entries(colors)
                  .filter(([_, enabled]) => enabled)
                  .map(([val]) => ({ value: val }))
              );
              updateColors(colors);
            }}
          />
        );
      })}
    </div>
  );
};
