import { useEffect, useState } from "react";

export function BackToTop() {
  const [scrollFromTop, setScrollTop] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      let documentScroll = e.target.documentElement.scrollTop;
      if (Math.abs(documentScroll - scrollFromTop) > 20) {
        setScrollTop(documentScroll);
        setIsScrollingUp(documentScroll < scrollFromTop);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollFromTop]);

  if (!isScrollingUp || scrollFromTop <= 0) {
    return null;
  }

  // TODO: Improve shadow
  return (
    <button
      className={`fixed shadow-2xl bottom-6 right-6 bg-indigo-700 flex z-10 items-center justify-center mr-2 rounded-full focus:outline-none hover:bg-indigo-800`}
      onClick={() => {
        window && window.scrollTo(0, 0);
      }}
    >
      <svg viewBox="0 0 24 24" width="48" height="48" fill="#fff">
        <g>
          <path d="M7.41 15.41l4.59-4.58 4.59 4.58 1.41-1.41-6-6-6 6z"></path>
        </g>
      </svg>
    </button>
  );
}
