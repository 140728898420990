export const DECK_QUERY = "deck";
export const SEARCH_QUERY = "search";
export const ITEMS_COUNT_QUERY = "itemsCount";

export const updateQueryParams = (params) => {
  if (!(window && window.URLSearchParams)) {
    return;
  }

  const searchParams = new URLSearchParams(window.location.search);
  params.forEach(({ name, value }) => {
    const oldValue = searchParams.get(name);
    if (oldValue !== value) {
      if (!value) {
        searchParams.delete(name);
      } else {
        searchParams.set(name, value);
      }
    }
  });

  const stringParams = searchParams.toString();
  if (
    (!stringParams && !window.location.search) ||
    "?" + stringParams === window.location.search
  ) {
    return;
  }
  window.history.replaceState(
    null,
    "",
    `${window.location.pathname}${stringParams ? `?${stringParams}` : ""}`
  );
};

export const getQueryParam = (name, defaultValue) => {
  if ("URLSearchParams" in window) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name) || defaultValue;
  }
  return defaultValue;
};

export const isCardDetailsPath = () =>
  window.location.pathname.includes("/cards/");

export const isMainPath = () => window.location.pathname === "/";
