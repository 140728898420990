import { identity } from "./text";

export function compareNumberAndString(a, b) {
  if (typeof a === "number") {
    return a - b;
  }
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export const getFieldUniquesValues = (cards, property) => {
  const uniqueValues = new Set();
  cards.forEach((it) => {
    let value = it[property];
    if (value) {
      if (typeof value === "string") {
        value = value.toLowerCase();
      }
      return uniqueValues.add(value);
    }
  });
  return [...uniqueValues].filter(identity).sort(compareNumberAndString);
};
