import React, { useEffect, useState, useContext, useReducer } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import "./App.css";

import {
  getQueryParam,
  isMainPath,
  ITEMS_COUNT_QUERY,
  SEARCH_QUERY,
  updateQueryParams,
} from "./utils/routes";

import { getImageURL, FRONT } from "./utils/cardImage";
import { DEFAULT_ITEMS_COUNT } from "./utils/constants";
import {
  SELECTED_CARD_KEY,
  saveToSession,
  getFromSession,
  TCG_PRICES_KEY,
  TCG_PRICES_EXPIRATION_KEY,
} from "./utils/sessionStorage";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {
  doSearch,
  getFilterShortestNameAndAlias,
  buildAdditionalFilters,
  formatFilters,
  getFiltersDefinition,
} from "./utils/searchParser";

import { AppContext, initialState } from "./context/AppContext";
import { DeckViewer } from "./components/DeckViewer";
import { CardGrid } from "./components/CardGrid";
import { CardDetails } from "./components/CardDetails";
import { Filter } from "./components/Filter";
import { Paragraph, Text, Title } from "./components/Text";
import { Header } from "./components/Header";
import { SearchExample, Welcome } from "./components/Welcome";
import { setMetadata } from "./utils/metatags";
import { BackToTop } from "./components/BackToTop";

import {
  getFromDB,
  INDEXDB_CATS_KEYS,
  INDEXDB_DB_KEY,
  saveToDB,
} from "./utils/indexedDB";

const ITEMS_SUM = 20;
const SELECTABLES = [
  "color",
  "type",
  "rarity",
  "energy",
  "combo_energy",
  "combo_power",
  "keyword",
  "power",
  "character",
  "special_trait",
  "era",
  "series",
];

const excludeFromFilters = ["color", "type"];
const MAX_ITEMS = 200;

const executeSearch = (
  searchTerm,
  updateCardList,
  filters,
  db,
  filtersDefinition
) => {
  let list = doSearch(searchTerm, filtersDefinition, db);
  updateCardList(list);
};

const onSWUpdate = (updateSWState) => (registration) => {
  updateSWState({ isUpdated: true, waitingWorker: registration.waiting });
};

const reloadPage = (waitingWorker) => {
  if (waitingWorker && waitingWorker.postMessage) {
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
  }
  window.location.reload(true);
};

const deleteFilter = (
  selectedOptions,
  property,
  baseFilters,
  searchTerm,
  filtersDefinition
) => {
  let allFilters = searchTerm.split(";");
  let stringsToDelete = [];

  baseFilters[property]
    .filter((baseOption) =>
      selectedOptions.every((option) => option.value !== baseOption.value)
    )
    .forEach((deletedFilter) => {
      if (!filtersDefinition[property].values) {
        stringsToDelete.push(`${property}=${deletedFilter.value}`);
        stringsToDelete.push(
          `${filtersDefinition[property].alias}=${deletedFilter.value}`
        );
      } else {
        Object.entries(filtersDefinition[property].values).forEach(
          ([alias, fullName]) => {
            if (fullName === deletedFilter.value) {
              stringsToDelete.push(`${property}=${alias}`);
              stringsToDelete.push(
                `${filtersDefinition[property].alias}=${alias}`
              );
            }
          }
        );
      }
    });

  stringsToDelete.forEach((sd) => {
    allFilters = allFilters.filter((it) => it !== sd);
  });

  // TODO: fix delete filter with multi option
  return formatFilters(allFilters);
};

const addNewFilter = (baseFilters, searchTerm, filters, filtersDefinition) => {
  const allFilters = new Set(searchTerm.split(";"));
  Object.entries(filters).forEach(([property, options]) => {
    if (options.length <= 0) {
      return;
    }

    options.forEach((option) => {
      const [filterAlias, shortestName] = getFilterShortestNameAndAlias(
        property,
        option.value,
        filtersDefinition
      );
      // TODO: consolidate filters
      allFilters.add(`${filterAlias}=${shortestName}`);
    });
  });

  return formatFilters(allFilters);
};

const onPropertyChange =
  (baseFilters, state, updateFilters, updateCardList, db) =>
  (property, selectedOptions, x, y) => {
    const filters = {
      ...baseFilters,
      [property]: selectedOptions,
    };

    if (!selectedOptions) {
      return;
    }
    let queries;
    if (selectedOptions.length < baseFilters[property].length) {
      queries = deleteFilter(
        selectedOptions,
        property,
        baseFilters,
        state.searchTerm,
        state.filtersDefinition
      );
    } else {
      queries = addNewFilter(
        baseFilters,
        state.searchTerm,
        filters,
        state.filtersDefinition
      );
    }

    updateFilters(filters);

    state.dispatch({
      payload: {
        searchTerm: queries,
      },
    });

    updateQueryParams([{ name: SEARCH_QUERY, value: queries }]);
    saveToSession(ITEMS_COUNT_QUERY, DEFAULT_ITEMS_COUNT);
    saveToSession(SELECTED_CARD_KEY, "");
    executeSearch(
      queries,
      updateCardList,
      filters,
      state.db,
      state.filtersDefinition
    );
  };

function addCardsToDB(originalDB, cards) {
  const dbMap = originalDB.reduce((newDB, card) => {
    newDB[card.number] = card;
    return newDB;
  }, {});
  cards.forEach((card) => (dbMap[card.number] = card));
  return Object.values(dbMap).map((card) => card);
}

function reducer(state, action) {
  if (action.type === "ADD_CARDS") {
    const db = addCardsToDB(state.db, action.payload.cards);
    return { ...state, db };
  }
  return { ...state, ...action.payload };
}

const fetchjson = (url) => {
  return fetch(url).then((res) => res.json());
};

function getCatName(catid) {
  return catid.replace("catid_", "").replace(".json", "");
}

const loadDB = async (dispatch) => {
  let db = [];
  try {
    const strDB = await getFromDB(INDEXDB_DB_KEY, "[]");
    db = JSON.parse(strDB);
    db = addCardsToDB(db, []);
    dispatch({ payload: { db } });
  } catch (e) {}

  const controller = await fetchjson(
    "https://d199nq6z71pauy.cloudfront.net/controller.json"
  );

  let categories = [];
  try {
    const strCategories = await getFromDB(INDEXDB_CATS_KEYS, "");
    categories = JSON.parse(strCategories);
  } catch (e) {}

  const loadCategories = new Set(controller.map((catid) => getCatName(catid)));
  const allCards = await Promise.all(
    controller
      .filter(
        (catid) => db.length === 0 || !categories.includes(getCatName(catid))
      )
      .map(async (catid) => {
        try {
          const cards = await fetchjson(
            `https://d199nq6z71pauy.cloudfront.net/${catid}`
          );
          return cards;
        } catch (e) {
          loadCategories.delete(getCatName(catid));
          return [];
        }
      })
  );

  saveToDB(INDEXDB_CATS_KEYS, Array.from(loadCategories));

  allCards.forEach((cards) => {
    db = addCardsToDB(db, cards);
  });

  saveToDB(INDEXDB_DB_KEY, db);
  return dispatch({
    payload: { db },
  });
};

function clearLocalStorage() {
  if (!window.localStorage) {
    return;
  }
  for (let key in window.localStorage) {
    if (key.includes("catid")) {
      window.localStorage.clear();
    }
  }
}

function App() {
  const [swState, updateSWState] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate(updateSWState),
    });
    if (isMainPath()) {
      const tags = [
        { property: "og:title", content: "DBSCG Toolbox" },
        {
          property: "og:description",
          content:
            "Advance Search and Deck builder for Dragon Ball Super Card Game",
        },
      ];
      const search = getQueryParam(SEARCH_QUERY, "");
      if (search !== "") {
        tags.push({
          property: "og:description",
          content: `Advance Search and Deck builder for Dragon Ball Super Card Game. Query: ${decodeURIComponent(
            search
          )}`,
        });
      }
      setMetadata(tags);
    }
    // clear all data because is not needed
    clearLocalStorage();
    loadDB(dispatch);
  }, []);

  useEffect(() => {
    dispatch({
      payload: { filtersDefinition: getFiltersDefinition(state.db) },
    });
  }, [state.db]);

  useEffect(() => {
    let storePrices;
    try {
      storePrices = JSON.parse(getFromSession(TCG_PRICES_KEY, {}));
    } catch (e) {
      storePrices = {};
    }
    const storePricesExpiration = getFromSession(TCG_PRICES_EXPIRATION_KEY, 0);

    if (
      Object.keys(storePrices || {}).length > 0 &&
      Date.now() < storePricesExpiration
    ) {
      dispatch({
        payload: {
          prices: storePrices,
          pricesExpiration: storePricesExpiration,
        },
      });
      return;
    }

    fetch(
      "https://d199nq6z71pauy.cloudfront.net/tcg_prices.json?x-je-request=html"
    )
      .then((res) => res.json())
      .then((prices) => {
        const pricesExpiration = Date.now() + 2 * 60 * 60 * 1000; // two hours
        saveToSession(TCG_PRICES_KEY, prices);
        saveToSession(TCG_PRICES_EXPIRATION_KEY, pricesExpiration);
        dispatch({
          payload: {
            prices,
            pricesExpiration,
          },
        });
      });
  }, []);

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <div
        className={`font-sans bg-gradient-to-tr from-green-500 to-blue-500 min-h-screen h-full min-w-screen max-w-screen m-0`}
      >
        {swState.isUpdated && (
          <div
            className="bg-yellow-300 w-screen fixed h-9 text-center align-middle cursor-pointer z-50"
            onClick={() => reloadPage(swState.waitingWorker)}
          >
            <Text>New Version Available!!! Click to update</Text>
          </div>
        )}
        <Router>
          <Route path="/">
            <Header />
          </Route>
          <Switch>
            <Route path="/deck">
              <DeckViewer />
              <CardDetailPane state={state} />
            </Route>
            <Route path="/cards/:number">
              <CardDetails />
            </Route>
            <Route path="/">
              <CardDatabase />
              <CardDetailPane state={state} />
            </Route>
          </Switch>
        </Router>
      </div>
    </AppContext.Provider>
  );
}

const CardDetailPane = ({ state }) => {
  if (!state.selectedCard) {
    return null;
  }
  return (
    <div className="invisible 2xl:visible fixed right-0 top-0 w-detail-xl border-l-2 border-green-200 overflow-y-auto h-full">
      <CardDetails propNumber={state.selectedCard} cardSize="s"></CardDetails>
    </div>
  );
};

const updateItemCountNo = (val, update) => {
  let intVal = parseInt(val);
  update(isNaN(intVal) ? 0 : intVal);
};

const CardDatabase = () => {
  const state = useContext(AppContext);
  const [list, updateCardList] = useState();

  let history = useHistory();

  const [itemsCount, updateItemCount] = useState(
    parseInt(getFromSession(ITEMS_COUNT_QUERY, DEFAULT_ITEMS_COUNT)) ||
      DEFAULT_ITEMS_COUNT
  );

  const [filters, updateFilters] = useState({});

  const [imagesLoadedCount, updateImagesLoadedCount] = useState(0);
  const gridID = "search";

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        const queries = new URLSearchParams(location.search);
        const querySearchTerm = queries.get(SEARCH_QUERY) || "";
        state.dispatch({
          payload: {
            searchTerm: querySearchTerm,
          },
        });
        saveToSession(SELECTED_CARD_KEY, "");
        saveToSession(ITEMS_COUNT_QUERY, DEFAULT_ITEMS_COUNT);
      }
    });
  }, [history, state]);

  useEffect(() => {
    if (!state.db) {
      return;
    }

    if (state.searchTerm === "" && !state.selectedCard) {
      updateImagesLoadedCount(0);
      updateItemCountNo(DEFAULT_ITEMS_COUNT, updateItemCount);
      saveToSession(SELECTED_CARD_KEY, "");
    }
    const builtFilters = buildAdditionalFilters(
      SELECTABLES,
      state.searchTerm,
      state.filtersDefinition
    );
    updateFilters(builtFilters);
    updateQueryParams([{ name: SEARCH_QUERY, value: state.searchTerm }]);
    saveToSession(ITEMS_COUNT_QUERY, DEFAULT_ITEMS_COUNT);
    executeSearch(
      state.searchTerm,
      updateCardList,
      builtFilters,
      state.db,
      state.filtersDefinition
    );
  }, [
    state.searchTerm,
    state.db,
    state.filtersDefinition,
    state.selectedCard,
    updateFilters,
    updateImagesLoadedCount,
    updateItemCount,
  ]);

  // preload images
  useEffect(() => {
    if (imagesLoadedCount === itemsCount && !!list) {
      list
        .slice(itemsCount, Math.min(itemsCount + ITEMS_SUM, MAX_ITEMS))
        .forEach((card) => {
          const img = new Image();
          img.src = getImageURL(card, FRONT);
        });
    }
  }, [imagesLoadedCount, list, itemsCount]);

  if (!list) {
    return <Title>Loading...</Title>;
  }
  let Main = (
    <div>
      <CardGrid
        list={list}
        itemsCount={itemsCount}
        imagesLoadedCount={imagesLoadedCount}
        updateImagesLoadedCount={updateImagesLoadedCount}
        title={`Results: ${list.length}`}
        allowDeckBuilding={true}
        gridID={gridID}
        loadMore={() => {
          const newCount = itemsCount + ITEMS_SUM;
          if (newCount > MAX_ITEMS) {
            return;
          }
          saveToSession(ITEMS_COUNT_QUERY, newCount);
          updateItemCountNo(newCount, updateItemCount);
        }}
      />
      <BackToTop />
    </div>
  );

  if (!state.searchTerm) {
    Main = <Welcome />;
  } else if (list.length === 0) {
    Main = <NoResults />;
  }
  return (
    <div>
      <div
        className={`pl-4 pb-4 flex gap-1 flex-col lg:flex-row lg:items-center ${
          state.selectedCard ? "2xl:mr-detail-xl 2xl:flex-wrap" : ""
        }`}
      >
        <Filter
          key={"color"}
          value={filters["color"]}
          property={"color"}
          onChange={onPropertyChange(
            filters,
            state,
            updateFilters,
            updateCardList,
            state.db
          )}
        />
        <Filter
          key={"type"}
          value={filters["type"]}
          property={"type"}
          onChange={onPropertyChange(
            filters,
            state,
            updateFilters,
            updateCardList,
            state.db
          )}
        />
      </div>
      <div className="ml-4 xl:flex">
        {state.showAllFilters && (
          <div
            className={`xl:mr-4 xl:mt-8 xl:w-60 grid grid-cols-2 gap-x-1 pr-1 xl:gap-x-auto xl:pr-auto xl:grid-cols-1 xl:auto-rows-min`}
          >
            {SELECTABLES.filter(
              (selectable) => !excludeFromFilters.includes(selectable)
            ).map((property) => (
              <div className="mt-2" key={property}>
                <Filter
                  key={property}
                  cards={state.db}
                  value={filters[property]}
                  property={property}
                  onChange={onPropertyChange(
                    filters,
                    state,
                    updateFilters,
                    updateCardList,
                    state.db
                  )}
                />
              </div>
            ))}
          </div>
        )}

        <div className={`flex-1`}>{Main}</div>
      </div>
    </div>
  );
};

const NoResults = () => {
  return (
    <Title className="pt-8">
      <Paragraph>No results found! </Paragraph>
      <Paragraph>
        Try searching by{" "}
        <SearchExample search="se~destroyer king">series</SearchExample>,{" "}
        <SearchExample search="r=sr;ce>0">
          rarity and combo energy
        </SearchExample>{" "}
        or <SearchExample search="t=b;nu~010">type and number</SearchExample>
      </Paragraph>
    </Title>
  );
};
export default App;
