import { set, get } from "idb-keyval";

export const INDEXDB_DB_KEY = "dbscgDB";
export const INDEXDB_CATS_KEYS = "dbscgCategories";

export const getFromDB = async (key, defaultValue) => {
  const dbVal = await get(key);
  return dbVal || defaultValue || "";
};

export const saveToDB = (key, val) => {
  let parsedVal = val;
  if (typeof val === "object") {
    parsedVal = JSON.stringify(val);
  }
  return set(key, parsedVal);
};
