import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Card } from "./Card";
import { Text } from "./Text";
import { AppContext } from "../context/AppContext";

import { getCanFlip } from "../utils/cardImage";
import { encodeCardList, buildDeck } from "../utils/encode";
import { responsizes, cardWidth } from "../utils/constants";
import { DECK_QUERY, getQueryParam, updateQueryParams } from "../utils/routes";
import { useInfiniteScroll } from "../utils/useInfiniteScroll";
import {
  getFromSession,
  saveToSession,
  SELECTED_CARD_KEY,
} from "../utils/sessionStorage";

const defaultDeck = {};

const CARD_SPACING =
  0.75 * parseFloat(getComputedStyle(document.documentElement).fontSize);

const navigateGrid =
  (gridID, cardGridContainerRef, index, history, card) => (e) => {
    if (!cardGridContainerRef || !cardGridContainerRef.current) {
      return;
    }
    const cardWithWithSpacing = cardWidth + CARD_SPACING;
    let cardsPerRowApproximation =
      cardGridContainerRef.current.offsetWidth > cardWithWithSpacing
        ? cardGridContainerRef.current.offsetWidth / cardWithWithSpacing
        : 1;
    let cardsPerRow =
      cardsPerRowApproximation % 1 < 0.95
        ? Math.floor(cardsPerRowApproximation)
        : Math.ceil(cardsPerRowApproximation);

    let next;
    switch (e.key) {
      case "ArrowLeft": {
        next = document.getElementById(`card_${gridID}_${index - 1}`);
        break;
      }
      case "ArrowRight": {
        next = document.getElementById(`card_${gridID}_${index + 1}`);
        break;
      }
      case "ArrowUp": {
        next = document.getElementById(`card_${gridID}_${index - cardsPerRow}`);
        break;
      }
      case "ArrowDown": {
        next = document.getElementById(`card_${gridID}_${index + cardsPerRow}`);
        break;
      }
      case "Enter": {
        saveToSession(SELECTED_CARD_KEY, card.number);
        history.push(`/cards/${card.number}`);
        break;
      }
      default: {
        next = null;
      }
    }
    if (next !== null && next !== undefined) {
      next.focus();
    }
  };

const buildGridDeck = (db) => {
  const encodedDeck = getQueryParam(DECK_QUERY, "");
  if (!encodedDeck) {
    return defaultDeck;
  }
  return buildDeck(encodedDeck, db);
};

export const CardGrid = ({
  list,
  itemsCount = 200,
  imagesLoadedCount,
  updateImagesLoadedCount,
  allowDeckBuilding = false,
  title,
  gridID,
  cardSize = "m",
  baseDeck,
  activeGrid = gridID,
  updateActiveGrid = () => {},
  baseDeckUpdate = () => {},
  loadMore = () => {},
}) => {
  let listToShow = list;
  if (itemsCount) {
    listToShow = list.slice(0, Math.min(itemsCount, 200));
  }

  const isDeckPath = window.location.pathname.includes("/deck");
  let history = useHistory();

  const state = useContext(AppContext);
  const [deck, updateDeck] = useState(baseDeck || buildGridDeck(state.db));

  const [deckURL, updateDeckURL] = useState(getQueryParam(DECK_QUERY, ""));
  const [shouldFocus, updateShouldFocus] = useState(true);

  const cardGridContainerRef = useRef(null);

  const updateDeckStatus = (deck) => {
    const encodedDeck = encodeCardList(deck);
    updateDeckURL(encodedDeck);
    updateDeck(deck);
    baseDeckUpdate(deck);
    updateQueryParams([{ name: DECK_QUERY, value: encodedDeck }]);
  };

  const [lastElementRef] = useInfiniteScroll(loadMore, false);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        const queries = new URLSearchParams(location.search);
        const deckTerm = queries.get(DECK_QUERY) || "";
        const deck = buildGridDeck(state.db);
        updateDeckURL(deckTerm);
        updateDeck(deck);
      }
    });
  }, [history, state.db]);

  useEffect(() => {
    if (baseDeck) {
      updateDeck(baseDeck);
    }
  }, [baseDeck]);

  useEffect(() => {
    const selectedCard = getFromSession(SELECTED_CARD_KEY, "");
    const index = list.findIndex((it) => it.number === selectedCard);
    if (index <= itemsCount && index !== -1) {
      const cardElement = document.getElementById(`card_${gridID}_${index}`);
      if (
        cardElement !== null &&
        document.activeElement !== cardElement &&
        window.document.activeElement.tagName !== "INPUT" &&
        activeGrid === gridID
      ) {
        cardElement.focus();
      }
    }
  }, [itemsCount, gridID, list, activeGrid]);

  const updateFocusCard = (card, index, forceFocus) => {
    if (!shouldFocus || (!forceFocus && activeGrid !== gridID)) {
      return;
    }
    saveToSession(SELECTED_CARD_KEY, card.number);
    state.dispatch({
      payload: {
        selectedCard: card.number,
      },
    });
    const cardElement = document.getElementById(`card_${gridID}_${index}`);
    if (cardElement !== null) {
      cardElement.focus();
    }
  };

  const onCardClick = (card, index) => (e) => {
    if (window && window.innerWidth >= responsizes["2xl"]) {
      updateActiveGrid(gridID);
      updateFocusCard(card, index, true);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="mt-4 mb-5 flex gap-3 md:items-center">
        <div className="flex md:justify-center md:items-center md:gap-3 gap-1 flex-col md:flex-row">
          {title && <Text className="font-bold border p-1">{title}</Text>}
          {deckURL && !isDeckPath && (
            <Text className="font-bold border p-1">
              {` Leader: ${getTotal({
                deck,
                section: "main",
                includeType: "LEADER",
              })} Main: ${getTotal({
                deck,
                section: "main",
                excludeType: "LEADER",
              })} Side: ${getTotal({
                deck,
                section: "side",
              })}`}
            </Text>
          )}
        </div>
        {deckURL && !isDeckPath && (
          <div className="flex md:justify-center md:items-center gap-3 flex-col md:flex-row ">
            <a href={`/deck?deck=${deckURL}`}>
              <Text className="flex justify-center align-middle content-center rounded text-indigo-700 font-bold bg-white pt-1 pb-1 pl-2 pr-2">
                View Deck
              </Text>
            </a>
            <button
              onClick={() => {
                updateDeck(defaultDeck);
                updateDeckURL("");
                updateQueryParams([{ name: DECK_QUERY, value: "" }]);
              }}
            >
              <span className="flex justify-center rounded text-indigo-700 font-bold bg-white pt-1 pb-1 pl-2 pr-2">
                Reset
              </span>
            </button>
          </div>
        )}
      </div>
      <div
        ref={cardGridContainerRef}
        className={`flex justify-center md:justify-start items-start gap-3 flex-wrap mt-4 pb-4 ${
          state.selectedCard ? "2xl:mr-detail-xl" : ""
        }`}
      >
        {listToShow.map((card, index) => {
          return (
            <div
              key={card.number}
              tabIndex={100 + index}
              onFocus={() => {
                updateFocusCard(card);
              }}
              ref={
                index === listToShow.length - 1 && itemsCount < list.length
                  ? lastElementRef
                  : null
              }
              onKeyDown={navigateGrid(
                gridID,
                cardGridContainerRef,
                index,
                history,
                card
              )}
              className="focus:ring-8 focus:outline-none ring-indigo-700 ring-opacity-90"
              id={`card_${gridID}_${index}`}
            >
              <Card
                card={card}
                canFlip={getCanFlip(card)}
                imagesLoadedCount={imagesLoadedCount}
                updateImagesLoadedCount={updateImagesLoadedCount}
                deck={deck}
                updateDeck={updateDeckStatus}
                allowDeckBuilding={allowDeckBuilding}
                prices={state.prices}
                onClick={onCardClick(card, index)}
                updateShouldFocus={updateShouldFocus}
                size={cardSize}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getTotal = ({ deck, section, includeType, excludeType }) => {
  let list = Object.values(deck).filter((card) => card.deck[section] > 0);

  if (includeType) {
    list = list.filter((card) => card.type === includeType);
  }

  if (excludeType) {
    list = list.filter((card) => card.type !== excludeType);
  }

  const total = list.reduce((tot, card) => tot + card.deck[section], 0);
  return total;
};
