export const SELECTED_CARD_KEY = "selectedCard";
export const TCG_PRICES_KEY = "tcgPrices";
export const TCG_PRICES_EXPIRATION_KEY = "tcgPricesExpiration";

export const getFromSession = (key, defaultValue) => {
  if (!(window && window.sessionStorage)) {
    return defaultValue || "";
  }
  return window.sessionStorage.getItem(key) || defaultValue || "";
};

export const saveToSession = (key, val) => {
  if (!(window && window.sessionStorage)) {
    return "";
  }
  let parsedVal = val
  if (typeof val === "object") {
    parsedVal = JSON.stringify(val)
  }
  return window.sessionStorage.setItem(key, parsedVal);
};
