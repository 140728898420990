export const focusOnCard = (id) => {
  const firstCard = document.getElementById(id);
  if (firstCard !== null) {
    firstCard.focus();
  }
};

export const stopDefaults = (fn) => (e) => {
  if (typeof fn === "function") {
    fn();
  }
  e.stopPropagation();
  e.preventDefault();
};
