export function TD({ children, className }) {
  return (
    <td
      className={`border py-2 px-4 text-center ${className ? className : ""}`}
    >
      {children}
    </td>
  );
}

export function TH({ children, className }) {
  return (
    <th
      className={`border py-2 px-4 text-center ${className ? className : ""}`}
    >
      {children}
    </th>
  );
}
