import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Paragraph, Text } from "./Text";
import { Card } from "./Card";
import { CostColor } from "./CostColor";
import { FRONT, BACK, getImageURL } from "../utils/cardImage";
import { costs, maxAllowedCards, tcgAffiliateCode } from "../utils/constants";
import { isCardDetailsPath, SEARCH_QUERY } from "../utils/routes";
import { capitalize, identity } from "../utils/text";

import { AppContext } from "../context/AppContext";
import { saveToSession, SELECTED_CARD_KEY } from "../utils/sessionStorage";
import { getFilterShortestNameAndAlias } from "../utils/searchParser";
import { setMetadata } from "../utils/metatags";
import { TD, TH } from "./Table";
import { formatter, getLowestRarityPrice, parsePrices } from "../utils/prices";

export const CardDetails = ({ propNumber, cardSize }) => {
  const state = useContext(AppContext);
  const [card, updateCard] = useState({});
  const [cardPrices, updateCardPrices] = useState([]);

  const { number = propNumber } = useParams();
  let lowestPrice = getLowestRarityPrice(cardPrices);

  useEffect(() => {
    const handleKey = (event) => {
      switch (event.key) {
        case "Escape": {
          saveToSession(SELECTED_CARD_KEY, "");
          state.dispatch({
            payload: {
              selectedCard: "",
            },
          });
          break;
        }
        default: {
          break;
        }
      }
    };

    document.addEventListener("keydown", handleKey);
  }, [state]);

  useEffect(() => {
    const details = state.db.find((it) => it.number === number);
    updateCard(details);
    if (!details) {
      return;
    }
    if (isCardDetailsPath()) {
      setMetadata([
        { property: "og:title", content: details.title },
        { property: "og:image", content: getImageURL(details) },
        {
          property: "og:description",
          content: details.skills_text.replace("<<<", "[").replace(">>>", "]"),
        },
      ]);
    }
  }, [number, state.db]);

  useEffect(() => {
    if (!card) {
      return;
    }
    updateCardPrices(parsePrices(card, state.prices));
  }, [card, state.prices]);

  if (!number || !card) {
    return <Paragraph>NOT FOUND</Paragraph>;
  }

  const showTitle = !!(card.title && card.title_back);

  const defaultTCGUrl = `https://www.tcgplayer.com/search/all/product?q=${encodeURIComponent(
    card.title
  )}&view=grid`;
  const lowestPriceUrl = lowestPrice.url
    ? `${lowestPrice.url}?utm_campaign=affiliate&utm_medium=${tcgAffiliateCode}&utm_source=${tcgAffiliateCode}`
    : defaultTCGUrl;

  return (
    <div>
      <div className="flex justify-center items-center p-4 bg-green-700 flex-1 text-xl relative">
        <Close state={state} />
        <Text>
          {`${card.title}${card.title_back ? ` / ${card.title_back}` : ""}`}
        </Text>
        <a
          className="ml-2 bg-blue-500 p-1 text-base rounded"
          target="_blank"
          rel="noreferrer"
          href={lowestPriceUrl}
        >
          <Text>
            {lowestPrice.lowPrice
              ? `${formatter.format(lowestPrice.lowPrice)}`
              : "TCG"}
          </Text>
        </a>
      </div>
      <div className="flex justify-center items-center flex-col p-4">
        <CardFace
          showTitle={showTitle}
          header=""
          card={card}
          side={FRONT}
          cardSize={cardSize}
          cardPrices={cardPrices}
        />
        {card.title_back !== "" && (
          <React.Fragment>
            <div className="border-b-2 m-4 border-white w-full max-w-xl" />
            <CardFace
              showTitle={showTitle}
              header="back"
              card={card}
              side={BACK}
              cardSize={cardSize}
            />
          </React.Fragment>
        )}
        <div className="border-b-2 m-4 border-white w-full max-w-xl" />
        <CardPrices prices={cardPrices} />
      </div>
    </div>
  );
};

const fn = () => {};

const CardFace = ({ header, card, side, cardSize, showTitle }) => {
  let {
    title,
    series,
    skills_text,
    power,
    combo_energy,
    combo_power,
    character,
    energy,
    number,
    type,
    special_trait,
    era,
    rarity,
    notes,
    limit,
    errata,
  } = card;

  if (side === BACK) {
    ({
      title_back: title,
      skills_back_text: skills_text,
      power_back: power,
      notes_back: notes,
    } = card);
  }

  return (
    <div>
      <div className="flex space-x-5 flex-col items-center md:flex-row md:items-start">
        <div className={`${!isCardDetailsPath() ? "w-48" : ""}`}>
          <Card
            card={card}
            size={cardSize}
            canFlip={false}
            allowDeckBuilding={false}
            updateDeck={fn}
            defaultSide={side}
          />
        </div>
        <div className="flex flex-1 md:max-w-detail space-y-2 flex-col mt-2">
          {showTitle && (
            <Paragraph className="font-bold text-lg">{title}</Paragraph>
          )}
          <Skill txt={skills_text} />
          <div className="pt-2 flex flex-wrap gap-1 gap-y-4">
            <Trait filterName="type" value={capitalize(type)} name="Type" />
            <Trait filterName="number" value={number} name="Number" />
            <Trait filterName="energy" value={energy} name="Energy" />
            <Trait filterName="power" value={power} name="Power" />
            <Trait
              filterName="combo_energy"
              value={combo_energy}
              name="Combo E"
            />
            <Trait
              filterName="combo_power"
              value={combo_power}
              name="Combo P"
            />
            <Trait filterName="character" value={character} name="Character" />
            <Trait filterName="era" value={era} name="Era" />
            <Trait
              filterName="special_trait"
              value={special_trait}
              name="Special Trait"
            />
            <Trait filterName="series" value={series} name="Series" />
            <Trait filterName="rarity" value={rarity} name="Rarity" />
            {limit !== maxAllowedCards && (
              <Trait filterName="limit" value={limit} name="Ban/Limit" />
            )}
            <Trait
              href={`http://www.dbs-cardgame.com/us-en/rule/errata-cards.php#${card.number}`}
              filterName="Has Errata"
              value={errata}
              name="Errata"
            />
            <Trait filterName="notes" value={notes} name="Notes" />
          </div>
        </div>
      </div>
    </div>
  );
};

const TraitContainer = ({ filterName, value, children, href }) => {
  const { filtersDefinition } = useContext(AppContext);
  const [filterAlias, shortestValue] =
    getFilterShortestNameAndAlias(filterName, value, filtersDefinition) || [];
  const fullLengthTrait = value.length > 40;

  let cs = "whitespace-nowrap";
  if (fullLengthTrait) {
    cs = "min-w-trait text-center";
  }
  const classes = `flex flex-1 ${cs} min-w flex-col justify-center items-center border`;

  if (!filterName && !href) {
    return <div className={classes}>{children}</div>;
  }
  return (
    <a
      href={href || getEnchanceSearch(`${filterAlias}=${shortestValue}`)}
      className={classes}
    >
      {children}
    </a>
  );
};

const Trait = ({ filterName, name, value, href }) => {
  if (!identity(value)) {
    return null;
  }

  const paragraphs = typeof value === "string" ? value.split("\\n") : [value];
  return (
    <TraitContainer href={href} filterName={filterName} value={value}>
      <Paragraph className="w-full flex justify-center items-center font-bold border-b pl-2 pr-2 border-green-200">
        {name}
      </Paragraph>
      {paragraphs.map((p) => (
        <Paragraph
          key={p + value}
          className="w-full flex justify-center items-center pl-2 pr-2"
        >
          {p}
        </Paragraph>
      ))}
    </TraitContainer>
  );
};

const Skill = ({ txt = "" }) => {
  const paragraphs = txt.split("\\n");

  return (
    <div className="border-b border-t border-green-200 pt-2 pb-2">
      {paragraphs
        .filter((it) => it)
        .map((paragraph, index) => (
          <Paragraph key={paragraph + index}>
            <SkillParagraph parentKey={paragraph + index} txt={paragraph} />
          </Paragraph>
        ))}
    </div>
  );
};

const SkillParagraph = ({ txt = "", parentKey = "" }) => {
  const parsedText = txt.replace(/<<</g, "$").replace(/>>>/g, "@");
  const words = parsedText.split(/(?=[$@])/).filter(Boolean);
  return words
    .filter((c) => c.trim() !== "@")
    .map((word, index) => {
      let key = parentKey + word + index;

      if (word.startsWith("$")) {
        const skill = word.replace("$", "").replace("@", "").trim();
        if (costs.includes(skill)) {
          return <CostColor key={key} cost={skill} />;
        }
        return <KeyWordSkill key={key} skill={skill} />;
      }

      //
      const txt = word.replace("@", "");
      return <EnhancedText parentKey={key} key={key} txt={txt} />;
    });
};

const EnhancedText = ({ txt, parentKey }) => {
  const { filtersDefinition } = useContext(AppContext);
  const filtersWithDelimiters = Object.keys(filtersDefinition).filter(
    (key) => !!filtersDefinition[key].delimiters
  );
  const delimeteresRegex = filtersWithDelimiters
    .map((key) => {
      return `[${filtersDefinition[key].delimiters}]`;
    })
    .join("|");
  const regExp = new RegExp(`(?=${delimeteresRegex})`);
  let array = txt.split(regExp).filter(Boolean);
  return array.map((txt, idx) => {
    const elementKey = parentKey + txt + idx;

    for (let index = 0; index < filtersWithDelimiters.length; index++) {
      const key = filtersWithDelimiters[index];
      const [start, end] = filtersDefinition[key].delimiters.split("");
      if (txt.startsWith(start)) {
        const [filterAlias, shortestValue] = getFilterShortestNameAndAlias(
          key,
          txt.replace(start, ""),
          filtersDefinition
        );
        return (
          <Text key={elementKey} className="underline">
            <a href={`${getEnchanceSearch(`${filterAlias}=${shortestValue}`)}`}>
              {txt + end}
            </a>
          </Text>
        );
      }
    }

    let parsedTxt = txt;
    for (let index = 0; index < filtersWithDelimiters.length; index++) {
      const key = filtersWithDelimiters[index];
      const end = filtersDefinition[key].delimiters.split("")[1];
      parsedTxt = parsedTxt.replace(end, "");
    }

    return <Text key={elementKey}>{parsedTxt}</Text>;
  });
};

const getSkillClasses = (skill) => {
  let lowerSkill = skill.toLowerCase();
  if (lowerSkill.startsWith("auto")) {
    return "bg-skill-blue text-white rounded-sm px-1";
  }
  if (lowerSkill.startsWith("counter") || lowerSkill.startsWith("field")) {
    return "bg-skill-green text-white rounded-sm px-1";
  }
  if (lowerSkill.startsWith("activate")) {
    return "bg-skill-orange text-white rounded-sm px-1";
  }
  if (lowerSkill.startsWith("awaken")) {
    return "bg-skill-yellow text-black rounded-sm px-1";
  }
  if (lowerSkill.startsWith("unison")) {
    return "bg-skill-black text-white rounded-sm px-1";
  }
  if (lowerSkill.startsWith("permanent")) {
    return "bg-skill-pink text-white rounded-sm px-1";
  }

  if (skill.match(/^[-+]?\d+$/)) {
    return "bg-skill-black text-white rounded-full w-7 h-7 inline-flex justify-center items-center";
  }

  return "bg-skill-red";
};

const getEnchanceSearch = (search) => {
  const params = new URLSearchParams(window.location.search);

  let newSearch = search;
  params.set(SEARCH_QUERY, newSearch);
  return `/?${params.toString()}`;
};

const KeyWordSkill = ({ skill }) => {
  let parsedSkill = skill;
  if (skill === "0") {
    parsedSkill = "±0";
  }
  return (
    <a
      href={getEnchanceSearch(`k=${skill}`)}
      className={`${getSkillClasses(skill)} mr-2`}
    >
      {parsedSkill}
    </a>
  );
};

const Close = ({ state }) => {
  if (isCardDetailsPath()) {
    return null;
  }
  return (
    <span
      className="inset-y-0 absolute right-2 flex items-center text-green-300"
      onClick={() => {
        saveToSession(SELECTED_CARD_KEY, "");
        state.dispatch({
          payload: {
            selectedCard: "",
          },
        });
      }}
    >
      <button
        type="submit"
        className="p-1 focus:outline-none focus:shadow-outline"
      >
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="w-6 h-6"
        >
          <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z" />
        </svg>
      </button>
    </span>
  );
};

function CardPrices({ prices }) {
  if (!prices) {
    return null;
  }

  return (
    <table className="table-auto w-full max-w-card-detail">
      <thead>
        <tr>
          <TH>
            <Text>Series</Text>
          </TH>
          <TH>
            <Text>Low</Text>
          </TH>
          <TH className="hidden sm:table-cell">
            <Text>Medium</Text>
          </TH>
          <TH className="hidden sm:table-cell">
            <Text>High</Text>
          </TH>
          <TH>
            <Text>Market</Text>
          </TH>
        </tr>
      </thead>
      <tbody>
        {prices.map((cp) => {
          const url = `${cp.url}?utm_campaign=affiliate&utm_medium=${tcgAffiliateCode}&utm_source=${tcgAffiliateCode}`;
          return (
            <tr key={`${cp.product_id}_${cp.subTypeName}`}>
              <TD>
                <a href={url} target="_blank" rel="noreferrer">
                  <Text>{`${cp.series}${
                    cp.subTypeName !== "Normal" ? `(${cp.subTypeName})` : ""
                  }`}</Text>
                </a>
              </TD>
              <TD>
                <Price>{cp.lowPrice}</Price>
              </TD>
              <TD className="hidden sm:table-cell">
                <Price>{cp.midPrice}</Price>
              </TD>
              <TD className="hidden sm:table-cell">
                <Price>{cp.highPrice}</Price>
              </TD>
              <TD>
                <Price>{cp.marketPrice}</Price>
              </TD>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function Price({ children }) {
  return <Text>{formatter.format(children)}</Text>;
}
