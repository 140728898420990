import { compareNumberAndString, getFieldUniquesValues } from "./filter";
import { identity } from "./text";
import { costs } from "./constants";

export const SEPARATOR = ";";
export const EQUAL = "=";
export const LIKE = "~";
export const NOT = "!";
export const GREATER = ">";
export const GREATEROREQUAL = ">=";
export const LESS = "<";
export const LESSOREQUAL = "<=";
export const OR = "|";
export const COMPARATORS = [
  GREATEROREQUAL,
  GREATER,
  LESSOREQUAL,
  LESS,
  LIKE,
  EQUAL,
  NOT,
];
export const OPERATORS = [SEPARATOR, OR, ...COMPARATORS];

const NUMERICAL_COMPARATORS = [
  EQUAL,
  GREATER,
  LESS,
  LESSOREQUAL,
  GREATEROREQUAL,
  NOT,
];
export const INCLUDE = 0;
export const EXCLUDE = 1;

function equalSplit(fieldVal, searchVal) {
  if (searchVal === fieldVal) {
    return true;
  }
  if (!fieldVal) {
    return false;
  }
  const fieldValsArr = fieldVal.split("/");
  return fieldValsArr.includes(searchVal);
}

export const getFiltersDefinition = (db) => {
  const filters = {
    color: {
      alias: "c",
      values: (function () {
        return getFieldUniquesValues(db, "color").reduce((values, v) => {
          const colorMap = { blue: "u" };
          const fullName = v.trim().toLowerCase();
          const [color1, color2] = fullName.split("/");
          let shortName = colorMap[color1] || color1.substring(0, 1);
          if (color2) {
            shortName = `${shortName}/${
              colorMap[color2] || color2.substring(0, 1)
            }`;
          }

          values[fullName] = fullName;
          values[shortName] = fullName;
          return values;
        }, {});
      })(),
      fields: "color",
      fieldType: "char",
      supportMultipleValues: true,
      supportedOperators: [EQUAL, NOT],
      defaultSelectValues: [
        "Black",
        "Blue",
        "Blue/Black",
        "Blue/Green",
        "Blue/Yellow",
        "Green",
        "Green/Black",
        "Green/Yellow",
        "Red",
        "Red/Black",
        "Red/Blue",
        "Red/Green",
        "Red/Yellow",
        "Yellow",
        "Yellow/Black",
      ],
    },
    keyword: {
      alias: "k",
      fields: "skills_text, skills_back_text",
      [EQUAL]: function (fieldValue, searchValue) {
        const regExp = /<<<([^<<<]+)>>>/g;
        let keywords = fieldValue.match(regExp) || [];
        return keywords.some((k) => k === `<<<${searchValue}>>>`);
      },
      [NOT]: function (fieldValue, searchValue) {
        const regExp = /<<<([^<<<]+)>>>/g;
        let keywords = fieldValue.match(regExp) || [];
        return !keywords.some((k) => k === `<<<${searchValue}>>>`);
      },
      [LIKE]: function (fieldValue, searchValue) {
        const regExp = /<<<([^<<<]+)>>>/g;
        let keywords = fieldValue.match(regExp) || [];
        return keywords.some((k) => k.includes(searchValue));
      },
      selectValues: (function () {
        const uniqueValues = new Set();
        db.forEach((it) => {
          const regExp = /<<<([^<<<]+)>>>/g;
          let keywords = it.skills_text.match(regExp) || [];
          keywords.concat(it.skills_back_text.match(regExp) || []);
          keywords.forEach((it) => {
            uniqueValues.add(it);
          });
        });
        return [...uniqueValues]
          .map((it) => it.replace("<<<", "").replace(">>>", ""))
          .filter(identity)
          .filter((it) => !costs.includes(it))
          .sort(compareNumberAndString)
          .map((it) => ({
            value: typeof it === "string" ? it.toLowerCase() : it,
            label: it,
          }));
      })(),
      supportedOperators: [EQUAL, NOT, LIKE],
    },
    name: {
      alias: "n",
      fields: "title,title_back",
      fieldType: "char",
      delimiters: "{}",
      supportMultipleValues: true,
      supportedOperators: [EQUAL, NOT, LIKE],
    },
    notes: {
      alias: "nt",
      fields: "notes",
      fieldType: "char",
      supportMultipleValues: true,
      supportedOperators: [EQUAL, NOT, LIKE],
    },
    special_trait: {
      alias: "st",
      fields: "special_trait",
      fieldType: "char",
      delimiters: "《》",
      supportMultipleValues: true,
      [EQUAL]: equalSplit,
      supportedOperators: [EQUAL, NOT, LIKE],
    },
    skills: {
      alias: "s",
      fields: "skills_text,skills_back_text",
      fieldType: "char",
      supportMultipleValues: true,
      supportedOperators: [EQUAL, NOT, LIKE],
    },
    titleOrDescription: {
      alias: "tod",
      fields: "title,title_back,skills_text,skills_back_text",
      fieldType: "char",
      supportMultipleValues: true,
      supportedOperators: [EQUAL, NOT, LIKE],
    },
    type: {
      alias: "t",
      values: {
        leader: "leader",
        l: "leader",
        zleader: "z-leader",
        zl: "z-leader",
        unison: "unison",
        u: "unison",
        extra: "extra",
        e: "extra",
        battle: "battle",
        b: "battle",
        zbattle: "z-battle",
        zb: "z-battle",
        zextra: "z-extra",
        ze: "z-extra",
      },
      fields: "type",
      fieldType: "char",
      supportMultipleValues: true,
      defaultSelectValues: [
        "BATTLE",
        "EXTRA",
        "LEADER",
        "UNISON",
        "Z-BATTLE",
        "Z-UNISON",
      ],
      supportedOperators: [EQUAL, NOT],
    },
    limit: {
      alias: "l",
      fields: "limit",
      fieldType: "number",
      supportMultipleValues: false,
      supportedOperators: NUMERICAL_COMPARATORS,
    },
    combo_energy: {
      alias: "ce",
      fields: "combo_energy",
      fieldType: "number",
      supportMultipleValues: true,
      supportedOperators: NUMERICAL_COMPARATORS,
    },
    combo_power: {
      alias: "cp",
      fields: "combo_power",
      fieldType: "number",
      supportMultipleValues: true,
      supportedOperators: NUMERICAL_COMPARATORS,
    },
    energy: {
      alias: "e",
      fields: "energy",
      fieldType: "number",
      supportMultipleValues: true,
      supportedOperators: NUMERICAL_COMPARATORS,
    },
    power: {
      alias: "p",
      fields: "power,power_back",
      fieldType: "number",
      supportMultipleValues: true,
      supportedOperators: NUMERICAL_COMPARATORS,
    },
    rarity: {
      alias: "r",
      fields: "rarity",
      fieldType: "char",
      values: (function () {
        const rarities = getFieldUniquesValues(db, "rarity").reduce(
          (values, v) => {
            const fullName = v.trim().toLowerCase();
            const name = fullName.match(/[^[]*/i)[0].trim().toLowerCase() || [];
            values[name] = fullName;
            const shortNameMatches = fullName.match(/\[(.*)\]/) || [];
            if (shortNameMatches.length > 1) {
              const shortName = shortNameMatches[1].trim().toLowerCase();
              values[shortName] = fullName;
            }
            values[fullName] = fullName;

            return values;
          },
          {}
        );
        return rarities;
      })(),
      supportMultipleValues: true,
      supportedOperators: [EQUAL, LIKE],
    },
    // TODO: fix series names
    series: {
      alias: "se",
      fields: "series",
      fieldType: "char",
      supportMultipleValues: true,
      supportedOperators: [EQUAL, LIKE],
    },
    character: {
      alias: "ch",
      fields: "character",
      delimiters: "〈〉",
      fieldType: "char",
      [EQUAL]: equalSplit,
      supportMultipleValues: true,
      supportedOperators: [EQUAL, LIKE],
    },
    era: {
      alias: "era",
      fields: "era",
      fieldType: "char",
      supportedOperators: [EQUAL, LIKE],
    },
    number: {
      alias: "nu",
      fields: "number",
      fieldType: "char",
      supportedOperators: [EQUAL, LIKE],
    },
  };
  Object.keys(filters).forEach((property) => {
    let getSelectValues =
      filters[property].getSelectValues || getFieldUniquesValues;

    let filterValues = getSelectValues(db, property);
    if (filterValues.length === 0 && filters[property].defaultSelectValues) {
      filterValues = filters[property].defaultSelectValues;
    }

    filters[property].filterValues = filterValues.map((it) => ({
      value: typeof it === "string" ? it.toLowerCase() : it,
      label: it,
    }));
  });
  return filters;
};

export const mapAliasToFilter = (filters) => {
  return Object.entries(filters).reduce((acc, [key, val]) => {
    acc[key] = key;
    if (val.alias) {
      val.alias.split(",").forEach((a) => {
        acc[a] = key;
      });
    }
    return acc;
  }, {});
};

const addQuery = (
  queries,
  filter,
  value,
  operator,
  type /*include or exclude */
) => {
  if (!queries[filter]) {
    queries[filter] = {
      include: [],
      exclude: [],
    };
  }
  if (
    type === INCLUDE &&
    !queries[filter].include.some(
      (it) => it.value === value && it.operator === operator
    )
  )
    queries[filter].include.push({
      value,
      operator,
    });
  return queries;
};

export const parseQuery = (query, filters) => {
  const allFilters = mapAliasToFilter(filters);
  let queries = {};
  if (!OPERATORS.some((op) => query.includes(op))) {
    queries = addQuery(queries, "titleOrDescription", query, LIKE, INCLUDE);
    return queries;
  }

  // if the search query has any of the operators try to parse
  const allOps = query.split(SEPARATOR);
  allOps.forEach((operation) => {
    let hasAComparator = false;
    operation = operation.trim();
    COMPARATORS.forEach((comparator) => {
      if (operation.includes(comparator) && !hasAComparator) {
        hasAComparator = true;
        let [attr, val] = operation.split(comparator);
        // bad operator
        if (!attr || !identity(val)) {
          return;
        }
        attr = attr.trim().toLowerCase();
        val = val.trim().toLowerCase();

        const fullAttr = allFilters[attr];
        const filterDetails = filters[fullAttr];
        // invalid attribute
        if (!filterDetails) {
          return;
        }

        // operator not supported
        if (!filterDetails.supportedOperators.includes(comparator)) {
          return;
        }

        // if there are no specific values just pass directly the value we looking for
        if (!filterDetails.values) {
          queries = addQuery(queries, fullAttr, val, comparator, INCLUDE);
          return;
        }

        // if the value is one of the valid values just pass that one
        if (filterDetails.values && filterDetails.values[val]) {
          queries = addQuery(
            queries,
            fullAttr,
            filterDetails.values[val],
            comparator,
            INCLUDE
          );
          return;
        }

        // if not try to split into valid values each value has to be a single letter
        const individualValues = val.split("");
        // if all the values are part of string
        if (individualValues.every((i) => filterDetails.values[i])) {
          individualValues.forEach((i) =>
            addQuery(
              queries,
              fullAttr,
              filterDetails.values[i],
              comparator,
              INCLUDE
            )
          );
        }
      }
    });

    if (!hasAComparator && operation) {
      queries = addQuery(
        queries,
        "titleOrDescription",
        operation,
        LIKE,
        INCLUDE
      );
    }
  });
  return queries;
};

const parseValByType = (type, val) => {
  if (!identity(val)) {
    return "";
  }
  if (type === "string") {
    return val.toString().toLowerCase();
  }
  if (type === "number") {
    try {
      return parseInt(val);
    } catch {
      return 0;
    }
  }
  return val.toString().toLowerCase();
};

const doOperation = (card, operation, attr, filters) => {
  const filterDetails = filters[attr];
  if (!filterDetails) {
    return false;
  }

  const fields = filterDetails.fields.split(",");
  const val = parseValByType(filterDetails.fieldType, operation.value);

  return fields.some((field) => {
    if (field == null || card[field] == null || val == null) {
      return false;
    }
    field = field.trim();
    const fieldVal = parseValByType(filterDetails.fieldType, card[field]);

    if (!identity(fieldVal)) {
      return false;
    }

    // if there is an execute function
    if (typeof filterDetails[operation.operator] === "function") {
      return filterDetails[operation.operator](fieldVal, val);
    }
    switch (operation.operator) {
      case EQUAL:
        // eslint-disable-next-line eqeqeq
        return fieldVal == val;
      case LIKE:
        return removeSpecialChars(fieldVal).includes(removeSpecialChars(val));
      case GREATER:
        return fieldVal > val;
      case GREATEROREQUAL:
        return fieldVal >= val;
      case LESS:
        return fieldVal < val;
      case LESSOREQUAL:
        return fieldVal <= val;
      default:
        return false;
    }
  });
};

export const buildAdditionalFilters = (
  selectables,
  searchTerm,
  filtersDefinition
) => {
  const queries = parseQuery(searchTerm, filtersDefinition);
  const filters = selectables.reduce((filters, property) => {
    if (!queries[property]) {
      filters[property] = [];
    } else {
      filters[property] = queries[property].include
        .filter((it) => it.value && it.operator === EQUAL)
        .map((it) => ({ value: it.value, label: it.value }));
    }
    return filters;
  }, {});

  return filters;
};

export const doSearch = (baseQuery, filters, db) => {
  const allQueries = baseQuery.split(OR);
  return allQueries.reduce((filteredCards, query) => {
    const queries = parseQuery(query, filters);
    const currentFilterCards = db.filter((card) => {
      return Object.entries(queries).every(([attr, operationTypes]) => {
        const hasIncludes = operationTypes.include.some((operation) => {
          return doOperation(card, operation, attr, filters);
        });
        const hasExcludes = operationTypes.exclude.some((operation) => {
          return doOperation(card, operation, attr, filters);
        });
        return hasIncludes && !hasExcludes;
      });
    });
    return filteredCards.concat(currentFilterCards);
  }, []);
};

export const getFilterShortestNameAndAlias = (
  type,
  value,
  filtersDefinition
) => {
  if (!filtersDefinition[type]) {
    return;
  }
  let parseValue = value;
  if (typeof value === "string") {
    parseValue = value.toLowerCase();
  }
  let name = parseValue;

  if (filtersDefinition[type].values) {
    Object.entries(filtersDefinition[type].values).forEach(
      ([alias, fullName]) => {
        if (fullName === parseValue && alias.length < name.length) {
          name = alias;
        }
      }
    );
  }
  return [filtersDefinition[type].alias || type, name];
};

export const removeSpecialChars = (str) => {
  return str.replace(/,|-|'|\./g, "");
};

export const formatFilters = (allFilters) => {
  const allFiltersArray = [...allFilters].filter((v) => v.trim());

  const filtersWithoutOperands = allFiltersArray
    .filter((filter) => !COMPARATORS.some((c) => filter.includes(c)))
    .join(";");
  const filtersWithOperands = allFiltersArray
    .filter((filter) => COMPARATORS.some((c) => filter.includes(c)))
    .join(";");
  return `${filtersWithOperands ? `${filtersWithOperands};` : ""}${
    filtersWithoutOperands || ""
  }`;
};
