import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  DECK_QUERY,
  getQueryParam,
  ITEMS_COUNT_QUERY,
  SEARCH_QUERY,
} from "../utils/routes";

import { AppContext } from "../context/AppContext";
import { Text } from "./Text";
import {
  getFromSession,
  saveToSession,
  SELECTED_CARD_KEY,
} from "../utils/sessionStorage";
import { DEFAULT_ITEMS_COUNT, responsizes } from "../utils/constants";
import { focusOnCard } from "../utils/dom";

export const Header = () => {
  const state = useContext(AppContext);
  const [searchInput, updateSearchInput] = useState(
    getQueryParam(SEARCH_QUERY, ""),
    ""
  );
  const searchRef = useRef(null);
  const deckPath = "/deck";
  const isSearchPath = ["/", deckPath].includes(window.location.pathname);
  const isDeckPath = [deckPath].includes(window.location.pathname);

  let history = useHistory();

  useEffect(() => {
    if (
      searchRef.current &&
      !getFromSession(SELECTED_CARD_KEY, "") &&
      isSearchPath
    ) {
      searchRef.current.focus();
    }

    const handleKey = (event) => {
      if (
        event.key === "f" &&
        window.document.activeElement.tagName !== "INPUT" &&
        searchRef &&
        searchRef.current
      ) {
        event.preventDefault();
        event.stopPropagation();
        searchRef.current.focus();
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => document.removeEventListener("keydown", handleKey);
  }, [searchRef, isSearchPath]);

  useEffect(() => {
    updateSearchInput(state.searchTerm);
  }, [state.searchTerm]);

  const resetInput = () => {
    updateSearchInput("");
    searchRef.current.focus();
  };

  const executeSearch = () => {
    if (
      !isSearchPath ||
      (isDeckPath && window && window.innerWidth < responsizes["md"])
    ) {
      if (!(window && window.URLSearchParams)) {
        return;
      }
      const params = new URLSearchParams(window.location.search);

      params.set(SEARCH_QUERY, searchInput);
      history.replace(`/?${params.toString()}`);
    }

    saveToSession(ITEMS_COUNT_QUERY, DEFAULT_ITEMS_COUNT);
    saveToSession(SELECTED_CARD_KEY, "");
    state.dispatch({
      payload: {
        selectedCard: "",
        searchTerm: searchInput,
      },
    });
  };

  const gridID = "search";

  let homeURL = "/";
  const deckQuery = getQueryParam(DECK_QUERY, "");
  if (deckQuery) {
    homeURL += `?deck=${deckQuery}`;
  }

  return (
    <div className="flex items-center gap-2 sm:gap-2 border-b pb-2 pt-2 mb-4 pl-4 pr-2 sm:pr-0  border-green-200">
      <a href={homeURL}>
        <Text className="flex sm:hidden">DBST</Text>
        <Text className=" hidden sm:flex">DBSCG TOOLBOX</Text>
      </a>
      <div className="flex relative max-w-3xl flex-1">
        <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-green-300">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
            onClick={() => {
              searchRef.current.focus();
            }}
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="w-6 h-6"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </span>
        <input
          className={`w-full sm:w-auto sm:flex-1 h-8 pl-10 pr-8 rounded focus:outline-none focus:bg-white bg-transparent text-gray-700 placeholder-gray-700`}
          placeholder="Search..."
          value={searchInput || ""}
          ref={searchRef}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              executeSearch();
              setTimeout(() => focusOnCard(`card_${gridID}_0`), 200);
            }
            if (e.key === "Escape") {
              resetInput();
            }
          }}
          onChange={(ev) => {
            updateSearchInput(ev.target.value);
          }}
          onBlur={() => {
            executeSearch();
          }}
        />
        {searchInput !== "" && (
          <span
            className="absolute inset-y-0 right-0 flex items-center text-green-300"
            onClick={() => {
              resetInput();
            }}
          >
            <button
              type="submit"
              className="p-1 focus:outline-none focus:shadow-outline"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z" />
              </svg>
            </button>
          </span>
        )}
      </div>
      <div className="mt-1 w-6 h-6">
        {isSearchPath && (
          <svg
            viewBox="0 0 512 512"
            alt="Filter"
            onClick={() =>
              state.dispatch({
                payload: {
                  showAllFilters: !state.showAllFilters,
                },
              })
            }
            className={`w-6 h-6 fill-current ${
              state.showAllFilters ? "text-indigo-700" : "text-green-200"
            }`}
          >
            <g>
              <path d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"></path>
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};
