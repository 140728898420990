import { Huffman } from "huffman-ts";

let huffman = Huffman.treeFromText("abcdef0123456789,");

function getListFromStr(str) {
  return str
    .split(",")
    .filter((x) => x)
    .reduce((list, it) => {
      const amount = it.substring(0, 1);
      const hexID = it.substring(1);
      const ID = parseInt(hexID, 16);
      list[ID] = parseInt(amount);
      return list;
    }, {});
}

export const SECTIONS = ["main", "side"];

function getListofShortIDs(cards, section) {
  if (!cards) {
    return [];
  }

  const cardMap = Object.values(cards).reduce((map, card) => {
    const shortID = card.id.toString(16);
    if (!map[shortID]) {
      map[shortID] = 0;
    }
    map[shortID] = map[shortID] + card.deck[section];
    return map;
  }, {});
  return Object.entries(cardMap)
    .filter(([shortID, times]) => times > 0)
    .map(([shortID, times]) => {
      return `${times}${shortID}`;
    })
    .sort();
}
export function encodeCardList(deck) {
  const encoded = huffman.encode(
    SECTIONS.map((section) => {
      return getListofShortIDs(deck, section).join(",");
    }).join(",,")
  );

  return encodeURIComponent(btoa(encoded));
}

export function decodeCardList(str) {
  try {
    const decodeStr = huffman.decode(atob(decodeURIComponent(str)));
    return decodeStr.split(",,").map(getListFromStr);
  } catch {
    return SECTIONS.map(() => []);
  }
}

export function buildDeck(encodedStr, db) {
  const decodedDeck = decodeCardList(encodedStr);
  return db.reduce((deck, card) => {
    const ID = card.id;
    decodedDeck.forEach((section, idx) => {
      const sectionName = SECTIONS[idx];
      if (section[ID]) {
        if (!deck[ID]) {
          deck[ID] = { ...card, deck: {} };
        }
        deck[ID].deck[sectionName] =
          (deck[ID].deck[sectionName] || 0) + section[ID];
      }
    });
    return deck;
  }, {});
}
