const getCostDetails = (cost) => {
  switch (cost) {
    case "red": {
      return ["bg-red-700", ""];
    }
    case "blue": {
      return ["bg-blue-800", ""];
    }
    case "green": {
      return ["bg-green-700", ""];
    }
    case "yellow": {
      return ["bg-yellow-300", ""];
    }
    case "black": {
      return ["bg-black", ""];
    }
    case "blue/black": {
      return ["bg-diagonal-split from-black to-blue-800", ""];
    }
    case "blue/green": {
      return ["bg-diagonal-split from-green-700 to-blue-800", ""];
    }
    case "blue/yellow": {
      return ["bg-diagonal-split from-yellow-300 to-blue-800", ""];
    }
    case "green/black": {
      return ["bg-diagonal-split from-black to-green-700", ""];
    }
    case "green/yellow": {
      return ["bg-diagonal-split from-yellow-300 to-green-700", ""];
    }
    case "red/black": {
      return ["bg-diagonal-split from-black to-red-700", ""];
    }
    case "red/blue": {
      return ["bg-diagonal-split from-blue-800 to-red-700", ""];
    }
    case "red/green": {
      return ["bg-diagonal-split from-green-700 to-red-700", ""];
    }
    case "red/yellow": {
      return ["bg-diagonal-split from-yellow-300 to-red-700", ""];
    }
    case "yellow/black": {
      return ["bg-diagonal-split from-black to-yellow-300", ""];
    }

    default: {
      return ["bg-white text-black", cost];
    }
  }
};

//linear-gradient(80deg, #ff0000 50%, #0000ff 50%);

export const CostColor = ({ cost, size, className, onClick = () => {} }) => {
  const [bgColor, txt] = getCostDetails(cost);
  let sizeClasses = "h-4 w-4";
  if (size === "m") {
    sizeClasses = "h-7 w-7 md:h-8 md:w-8";
  }
  return (
    <span
      onClick={onClick}
      className={`${className} ${bgColor} ${sizeClasses} bg-grad bg-blue rounded-full mr-1 bg-yell items-center justify-center inline-flex text-xs align-middle`}
    >
      {txt}
    </span>
  );
};
