export const capitalize = (txt) => {
  if (!txt) {
    return txt;
  }
  if (txt.length < 2) {
    return txt.toUpperCase();
  }
  return txt[0].toUpperCase() + txt.substring(1).toLowerCase();
};

export function identity(val) {
  return val !== undefined && val !== null && val !== "";
}
