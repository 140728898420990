export const FRONT = 1;
export const BACK = 2;

const IMAGE_BASE_URL = "https://d2ar7rm9nfi2xh.cloudfront.net";

export const getImageURL = (card, side) => {
  if (!card.number) {
    return "";
  }

  const back = side === BACK ? "_b" : "";
  const backupImage = `${IMAGE_BASE_URL}/${card.number}${back}.png`;

  let image = card.image_front;
  if (side === BACK) {
    image = card.image_back;
  }
  return image || backupImage;
};

export function getCanFlip(card) {
  return !!card.skills_back_text;
}
