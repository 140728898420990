import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";

import { Text } from "./Text";

import { getImageURL, FRONT, BACK } from "../utils/cardImage";
import { LEADER } from "../utils/constants";

import { SECTIONS } from "../utils/encode";
import { getFromSession, SELECTED_CARD_KEY } from "../utils/sessionStorage";
import { isCardDetailsPath } from "../utils/routes";
import { formatter, getLowestRarityPrice, parsePrices } from "../utils/prices";
import { stopDefaults } from "../utils/dom";

function flipCard(canFlip, side, toggleSide) {
  // TODO: Flip to the back side if it's not double sided
  if (!canFlip) {
    return;
  }
  if (side === FRONT) {
    toggleSide(BACK);
  } else {
    toggleSide(FRONT);
  }
}

const CardContainer = ({ children, to, onClick }) => {
  if (isCardDetailsPath()) {
    return <div onClick={onClick}>{children}</div>;
  }

  const searchParams = new URLSearchParams(window.location.search);

  const stringParams = searchParams.toString();
  return (
    <a
      href={`${to}${stringParams ? "?" + stringParams : ""}`}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export const Card = ({
  card,
  canFlip,
  allowDeckBuilding,
  imagesLoadedCount,
  deck,
  defaultSide = FRONT,
  updateDeck,
  updateShouldFocus,
  size = "m",
  prices,
  updateImagesLoadedCount = () => {},
  onClick = () => {},
}) => {
  const [side, toggleSide] = useState(defaultSide);
  const [showPlaceholder, updateShowPlaceholder] = useState(true);
  const cardPrices = parsePrices(card, prices);
  useEffect(() => {
    const handleKey = (event) => {
      const selectedCard = getFromSession(SELECTED_CARD_KEY);
      if (
        window.document.activeElement.tagName === "INPUT" ||
        !selectedCard ||
        selectedCard !== card.number ||
        !deck
      ) {
        return;
      }

      if (event.key.match(/[0-9]/)) {
        stopDefaults(() => {
          const desiredAmount = parseInt(event.key);
          const amountMain = getAmount(card, deck, SECTIONS[0]);
          updateCardInDeck(
            card,
            deck,
            "main",
            updateDeck,
            desiredAmount - amountMain
          );
        })(event);
        return;
      }

      switch (event.key) {
        case "a": {
          stopDefaults(() => addCardToDeck(card, deck, "main", updateDeck))(
            event
          );
          break;
        }
        case "q": {
          stopDefaults(() =>
            removeCardFromDeck(card, deck, "main", updateDeck)
          )(event);
          break;
        }
        case "s": {
          stopDefaults(() => addCardToDeck(card, deck, "side", updateDeck))(
            event
          );
          break;
        }
        case "w": {
          stopDefaults(() =>
            removeCardFromDeck(card, deck, "side", updateDeck)
          )(event);
          break;
        }
        case "t": {
          stopDefaults(() => flipCard(canFlip, side, toggleSide))(event);
          break;
        }
        default: {
          break;
        }
      }
    };

    document.addEventListener("keydown", handleKey);
    return () => document.removeEventListener("keydown", handleKey);
  }, [card, deck, updateDeck, canFlip, side]);

  useEffect(() => {
    // preload back if can be flipped
    if (canFlip && card) {
      const img = new Image();
      let flippedSide = side;
      if (defaultSide === FRONT) {
        flippedSide = BACK;
      } else {
        flippedSide = FRONT;
      }
      img.src = getImageURL(card.number, flippedSide);
    }
  }, [card, canFlip, side, defaultSide]);

  const mobileHandlers = useSwipeable({
    onSwipedLeft: () => {
      flipCard(canFlip, side, toggleSide);
    },
    onSwipedRight: () => {
      flipCard(canFlip, side, toggleSide);
    },
    trackMouse: true,
  });

  if (!card) {
    return null;
  }

  const amountMain = getAmount(card, deck, SECTIONS[0]);

  const amountSide = getAmount(card, deck, SECTIONS[1]);
  const lowestPrice = getLowestRarityPrice(cardPrices);

  return (
    <CardContainer to={`/cards/${card.number}`} onClick={onClick}>
      <div
        className={`relative ${
          size === "m" ? "h-ch-m w-cw-m" : "h-ch-s w-cw-s "
        }`}
      >
        {lowestPrice.lowPrice !== undefined && (
          <div className="absolute flex top-24 -right-1 z-10 shadow-lg">
            <div className="relative">
              <span className="price-tag-triangle" />
              <Text className="bg-blue-600 p-1">
                {formatter.format(lowestPrice.lowPrice)}
              </Text>
            </div>
          </div>
        )}
        <div className=" absolute flex top-5 -right-1">
          {!!amountMain && (
            <span className="flex items-center justify-center px-2 py-1 mr-1 text-xs font-bold leading-none text-white bg-indigo-700 rounded-full z-10">
              {amountMain}
            </span>
          )}
          {!!amountSide && (
            <span className="flex items-center justify-center px-2 py-1 mr-1 text-xs font-bold leading-none text-white bg-indigo-400 rounded-full z-10">
              {amountSide}
            </span>
          )}
        </div>
        {(side === FRONT || side === BACK) && (
          <img
            {...mobileHandlers}
            src={getImageURL(card, side)}
            className={`relative rounded ${showPlaceholder ? "h-0" : ""}`}
            alt={card.title}
            onLoad={() => {
              updateShowPlaceholder(false);
              updateImagesLoadedCount(imagesLoadedCount + 1);
            }}
            onError={() => {
              updateShowPlaceholder(true);
            }}
          ></img>
        )}
        {/* TODO: cleanup */}
        {/* {((!canFlip && side === BACK) || showPlaceholder) && (
          <PlaceholderSVG {...mobileHandlers} className={`object-cover`} />
        )} */}
        {canFlip && (
          <div className="absolute bottom-1 left-28 flex">
            <Button
              onClick={stopDefaults(() => {
                flipCard(canFlip, side, toggleSide);
              })}
              color={700}
              updateShouldFocus={updateShouldFocus}
              className="content-center"
            >
              <svg viewBox="0 0 24 24" width="18" height="18" fill="#fff">
                <g>
                  <path d="M6.99 11l-3.99 4 3.99 4v-3h7.01v-2h-7.01v-3zm14.01-2l-3.99-4v3h-7.01v2h7.01v3l3.99-4z" />
                </g>
              </svg>
            </Button>
          </div>
        )}
        {allowDeckBuilding && (
          <div className="absolute bottom-1 left-2 flex">
            <Button
              onClick={stopDefaults(() =>
                addCardToDeck(card, deck, "main", updateDeck)
              )}
              updateShouldFocus={updateShouldFocus}
              color={700}
            >
              <svg viewBox="0 0 24 24" width="18" height="18" fill="#fff">
                <g>
                  <path d="M19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" />
                </g>
              </svg>
            </Button>
            <Button
              onClick={stopDefaults(() =>
                removeCardFromDeck(card, deck, "main", updateDeck)
              )}
              updateShouldFocus={updateShouldFocus}
              color={700}
            >
              <svg viewBox="0 0 24 24" width="18" height="18" fill="#fff">
                <g>
                  <path d="M19 13h-14v-2h14v2z" />
                </g>
              </svg>
            </Button>

            {card.type !== LEADER && (
              <React.Fragment>
                <Button
                  onClick={stopDefaults(() =>
                    addCardToDeck(card, deck, "side", updateDeck)
                  )}
                  updateShouldFocus={updateShouldFocus}
                  color={400}
                >
                  <svg viewBox="0 0 24 24" width="18" height="18" fill="#fff">
                    <g>
                      <path d="M19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" />
                    </g>
                  </svg>
                </Button>
                <Button
                  onClick={stopDefaults(() =>
                    removeCardFromDeck(card, deck, "side", updateDeck)
                  )}
                  updateShouldFocus={updateShouldFocus}
                  color={400}
                >
                  <svg viewBox="0 0 24 24" width="18" height="18" fill="#fff">
                    <g>
                      <path d="M19 13h-14v-2h14v2z" />
                    </g>
                  </svg>
                </Button>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </CardContainer>
  );
};

const updateCardInDeck = (card, deck, section, updateDeck, delta = 1) => {
  let cardInDeck = deck[card.id] ? deck[card.id] : { ...card, deck: {} };
  const newVal = Math.max((cardInDeck.deck[section] || 0) + delta, 0);
  const newDeck = {
    ...deck,
    [card.id]: {
      ...cardInDeck,
      deck: { ...cardInDeck.deck, [section]: newVal },
    },
  };

  // if all sections are 0 remove it from the list
  const totalCount = Object.values(newDeck[card.id].deck).reduce(
    (sum, val) => sum + val,
    0
  );
  if (totalCount === 0) {
    delete newDeck[card.id];
  }

  updateDeck(newDeck);
};

const addCardToDeck = (card, deck, section, updateDeck) => {
  updateCardInDeck(card, deck, section, updateDeck, 1);
};

const removeCardFromDeck = (card, deck, section, updateDeck) => {
  updateCardInDeck(card, deck, section, updateDeck, -1);
};

const Button = ({ onClick, children, color, updateShouldFocus }) => {
  return (
    <button
      onMouseDown={() => {
        updateShouldFocus(false);
      }}
      onMouseUp={() => {
        updateShouldFocus(true);
      }}
      onClick={onClick}
      className=" pt-6"
    >
      <Text
        className={`bg-indigo-${color} flex z-10 items-center justify-center h-5 w-5 mr-2 rounded-full focus:outline-none hover:bg-indigo-${
          color + 100
        }`}
      >
        {children}
      </Text>
    </button>
  );
};

const getAmount = (card, deck, section) => {
  if (!deck) {
    return card && card.deck && card.deck[section];
  }

  // card is not in deck
  if (!deck[card.id]) {
    return 0;
  }
  return deck[card.id].deck[section];
};
