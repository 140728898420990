import React from "react";

export const Text = ({ children, className, onClick = () => {} }) => {
  return (
    <span className={`text-white ${className || ""}`} onClick={onClick}>
      {children}
    </span>
  );
};

export const Paragraph = ({ children, className }) => {
  return <Text className={`block ${className || ""}`}>{children}</Text>;
};

export const Title = ({ children, className }) => {
  return (
    <Text className={`block ${className || ""} text-3xl`}>{children}</Text>
  );
};

export const SubTitle = ({ children, className }) => {
  return (
    <Text className={`block ${className || ""} text-2xl m-2`}>{children}</Text>
  );
};
